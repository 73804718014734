import React from "react"

import { Link } from "gatsby"
import Img from "gatsby-image"
import Hero from "../components/hero"
import TeachGrid from "../components/teachGrid"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Button from "../components/button"

export const TeachingPageTemplate = ({ props }) => {
    return (
        <div style={{
            //minHeight: '80vh'
        }} id={props.id}>
            <Hero imageSrc={props.hero}>
                <h1>{props.title}</h1>
            </Hero>
            <MDXRenderer>{props.children}</MDXRenderer>
        </div>
    )
}

const TeachingPage = (props) => {
    console.log(props);
    return (
        <>
            <div style={{
                //minHeight: '80vh',
                marginBottom: '2rem',
            }} id={props.id}>
                <Hero imageSrc={props.hero}
                    textColor={props.textColor}>
                    <h1>{props.title}</h1>
                </Hero>
                <MDXRenderer>{props.children}</MDXRenderer>
            </div>
            <div style={{
                marginBottom: "2rem"
            }}>
                <Link to={props.buttonLink || "/contact"}>
                    <Button >
                        {props.buttonText || "Contact for booking"}
                    </Button>
                </Link>
                {props.extraImage && <div>
                    {props.imageLink?<a href={props.imageLink} target="_blank" rel="noreferrer noopener">
                        <Img fluid={props.extraImage} style={{
                            width: "200px",
                            display: "block",
                            margin: "0 auto",
                        }} />
                    </a>:
                    <Img fluid={props.extraImage} style={{
                        width: "200px",
                        display: "block",
                        margin: "0 auto",
                    }} />}

                </div>}
            </div>
            <TeachGrid />
        </>
    )
}

export default TeachingPage