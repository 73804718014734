import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import ImageWithText from "../components/imageWithText"
import Grid from "../components/grid"

const TeachGrid = () => {
    const data = useStaticQuery(graphql`
query TeachGrid {
    choral: mdx(fileAbsolutePath: {regex: "/choral-conducting.md/"}) {
     
            frontmatter {
              hero {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
      }
    vocal: mdx(fileAbsolutePath: {regex: "/vocal-technique.md/"}) {

            frontmatter {
              hero {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }

        }
      }
    piano: mdx(fileAbsolutePath: {regex: "/piano-teaching.md/"}) {

            frontmatter {
              hero {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }

        }
      }
    beginnings: mdx(fileAbsolutePath: {regex: "/musical-beginnings.md/"}) {

            frontmatter {
              hero {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }

        }
      }
}
`)
    return (

        <Grid style={{
            height: '20vh',
        }} gap="3px">


            <Link to="/choral-conducting">
                <ImageWithText src={data.choral.frontmatter.hero.childImageSharp.fluid}>
                    <p>Choral conducting</p>
                </ImageWithText>
            </Link>


            <Link to="/vocal-technique">
                <ImageWithText src={data.vocal.frontmatter.hero.childImageSharp.fluid}>
                    <p>Vocal</p>
                </ImageWithText>
            </Link>


            <Link to="/piano">
                <ImageWithText src={data.piano.frontmatter.hero.childImageSharp.fluid}>
                    <p>Piano</p>
                </ImageWithText>
            </Link>


            <Link to="/musical-beginnings">
                <ImageWithText src={data.beginnings.frontmatter.hero.childImageSharp.fluid}>
                    <p>Musical beginnings</p>
                </ImageWithText>
            </Link>

        </Grid>

    )
}

export default TeachGrid
