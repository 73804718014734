import React from "react"
import { graphql } from "gatsby"

import TeachingPage from "../components/teachingView"
import SEO from "../components/seo"

const Piano = ({ data }) => {

    const hero = data.mdx.frontmatter.hero.childImageSharp.fluid.src
    const heading = data.mdx.frontmatter.heading
    const buttonText= data.mdx.frontmatter.buttonText
  const buttonLink= data.mdx.frontmatter.buttonLink
  const extraImage = data.mdx.frontmatter.image?data.mdx.frontmatter.image.childImageSharp.fluid:null
  const imageLink = data.mdx.frontmatter.imageLink

    return (
        <div id="top">
            <SEO
                title="Piano teaching"
                keywords={['piano education', 'piano lessons', 'piano teacher']}
            />


<TeachingPage hero={hero} title={heading} buttonLink={buttonLink} buttonText={buttonText} extraImage={extraImage} imageLink={imageLink}>
        {data.mdx.body}
      </TeachingPage>
        </div >
    )
}



export default Piano

export const query = graphql`
query Piano {
    mdx(fileAbsolutePath: {regex: "/piano-teaching.md/"}) {
      frontmatter {
        heading
        hero {
          childImageSharp {
            fluid(maxWidth: 2500) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        buttonText
        buttonLink
        image {
          childImageSharp {
            fluid(maxWidth: 2500) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageLink
      }
      body
    }
  }
`